<template>
  <div class="page-result">
    <div class="left-drag">
      <div class="tip-msg-min" v-if="showTip">
        Drag and drop to add blocks
        <i class="el-icon-close" @click="showTip = false"></i>
      </div>
      <div class="title-text">Customize the Page</div>
      <draggable
        v-show="currentTab === 'component'"
        class="drag-area"
        tag="div"
        v-model="sourceList"
        :group="{ name: 'g1', pull: 'clone', put: false }"
        :move="checkMove"
        animation="300"
        :sort="false"
        @end="dragAdd"
        filter=".forbid"
      >
        <div
          v-for="el in sourceList"
          :key="el.type"
          class="drag-item"
          :class="{
            activeLight: currentActive === el.type,
            forbid: noAdd === true || el.existence >= el.limit
          }"
          @click.stop="addNode(el)"
          v-if="
            el.islive === undefined ||
            ($route.query.type === 'live' && el.islive === true) ||
            ($route.query.type === 'pre' && el.islive === false)
          "
        >
          <div v-if="el.type === 'menu-tab'">
            <div class="img-box menu-tab"></div>
            <div>菜单栏</div>
          </div>
          <div v-if="el.type === 'introduction'">
            <div class="img-box introduction"></div>
            <div>Introduction</div>
          </div>
           <div v-if="el.type === 'album'">
            <div class="img-box album"></div>
            <div>Image Group</div>
          </div>
          <div v-if="el.type === 'image-swiper'">
            <div class="img-box image-swiper"></div>
            <div>轮播图</div>
          </div>
          <div v-if="el.type === 'subscribe'">
            <div class="img-box subscribe"></div>
            <div>Card</div>
          </div>
          <div v-if="el.type === 'image-article'">
            <div class="img-box image-article"></div>
            <div>图文</div>
          </div>
          <div v-if="el.type === 'video-player'">
            <div class="img-box video-player"></div>
            <div>播放器</div>
          </div>
          <div v-if="el.type === 'video-list'">
            <div class="img-box video-list"></div>
            <div>Video</div>
          </div>
          <div v-if="el.type === 'comment'">
            <div class="img-box comment"></div>
            <div>评论</div>
          </div>
          <div v-if="el.type === 'file'">
            <div class="img-box file"></div>
            <div>Document</div>
          </div>
          <div v-if="el.type === 'banner'">
            <div class="img-box banner"></div>
            <div>Banner</div>
          </div>
          <div v-if="el.type === 'live-info'">
            <div class="img-box live-info"></div>
            <div>直播信息</div>
          </div>
          <div v-if="el.type === 'countdown'">
            <div class="img-box countdown"></div>
            <div>开播倒计时</div>
          </div>
          <div v-if="el.type === 'guide'">
            <div class="img-box guide"></div>
            <div>Guide Map</div>
          </div>
          <div class="limit" v-if="el.limit">
            {{ el.existence }}/{{ el.limit }}
          </div>
        </div>
      </draggable>
      <div class="theme-box" v-show="currentTab === 'theme'">
        <div class="theme-text">风格配色选择</div>
        <div
          class="theme-item"
          v-for="(item, index) in colorSchemes"
          :key="index"
          @click="changeTheme(item, index)"
        >
          <div class="key">{{ item.label }}</div>
          <div class="value">
            <div
              class="color-item"
              v-for="item in item.colors"
              :key="item"
              :style="{ background: item }"
            ></div>
            <div
              class="value-select"
              v-if="stylesheet.type === item.name"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-drag">
      <div
        class="page-viewport"
        :style="{ background: stylesheet.style.backgroundColor.value }"
      >
        <div class="phone-head">
          <div class="text overout">{{ $route.query.name }}</div>
        </div>
        <nested
          :modules="resultList"
          @updataResult="updataResult"
          @closeEdit="closeEdit"
          @renderNoAdd="renderNoAdd"
          @deleteCom="deleteCom"
          :stylesheet="stylesheet"
        />
        <div
          style="height: 68px; width: 100%"
          v-if="$route.query.type === 'pre'"
        ></div>
        <div class="c-reservation-foote" v-if="$route.query.type === 'pre'">
          <el-button
            class="c-reservation-footer--btn"
            :style="{ background: stylesheet.style.buttonColor.value }"
          >
            {{
              $route.query.status == '1'
                ? '进入直播'
                : $route.query.status == '2'
                ? '付费观看'
                : '密码观看'
            }}
          </el-button>
        </div>
        <div id="resultDrag" style="width: 1px; height: 1px"></div>
        <!--        <div class="iphone-8">iPhone 8手机高度</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { uid } from 'uid'
import Nested from '../nested'
import config from '../config'
import { cloneDeep } from 'lodash'

export default {
  props: {
    currentTab: {
      type: String,
      default: 'component'
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    resultList: {
      handler(newV) {
        if (this.initOver) {
          let val = this.equals(newV, this.resultListCopy)
          if (!val) {
            this.isChange = true
          } else {
            this.isChange = false
          }
        }
        let tiemr = setTimeout(() => {
          clearTimeout(tiemr)
          tiemr = null
          if (
            document.getElementsByClassName('com-common').length &&
            document.getElementsByClassName('com-common')[0].style.display ===
              'none'
          ) {
            this.noAdd = true
          } else {
            if (JSON.stringify(newV).indexOf('indexOfGuideSwitch') > 0) {
              this.noAdd = false
            } else {
              this.noAdd = undefined
            }
          }
        }, 300)
      },
      deep: true
    },
    isChange(newV) {
      if (newV) {
        let msg = this.$route.query.edit ? 'Changes not saved. Are you sure you want to leave?' : 'The change has not been applied, are you sure you want to leave？'
        window.onbeforeunload = () => {
          return msg
        }
      } else {
        window.onbeforeunload = null
      }
    }
  },
  components: {
    draggable,
    Nested
  },
  data() {
    return {
      copySourceList: cloneDeep(config.copySourceList), // 备份需复制
      currentActive: '', // 当前选中的那个组件
      sourceList: cloneDeep(config.sourceList),
      resultList:
        this.$route.query.isLiveDecorated ||
        this.$route.query.isPreviewDecorated ||
        this.$route.query.templateId
          ? []
          : cloneDeep(config.resultList),
      resultListCopy: [], // 初始化结果集的备份，用来监控是否有改变
      colorSchemes: cloneDeep(config.colorSchemes),
      isChange: false, // 是否改动过
      images: [], // 文档图片
      initOver: false, // 初始化结束
      noAdd: undefined, // 点击左侧组件能添加到右侧展示栏中
      showTip: true
    }
  },
  async created() {
    // 如果直播名称name存在，name直播简介组件默认标题
    if (this.$route.query.name) {
      this.copySourceList[7].title = this.$route.query.name
      this.sourceList[7].title = this.$route.query.name
    }
    // 获取企业名称信息和logo
    // await this.getOrg();
    // todo 如果diy存在则是从自定义过来
    if (this.$route.query.diy && this.$route.query.type === 'live') {
      let sourceUrl = sessionStorage.getItem('LIVE_COVER')
      // 需要播放器默认绑定当前直播
      // 如果是自定义装修，如果用户在设置中上传了封面图，那么默认的播放器封面图就是上传的图片，新增的播放器封面不使用这张图
      sourceUrl && (this.resultList[0].coverUrl = sourceUrl)
      this.resultList[0].source = {
        id: this.$route.query.liveId,
        type: 'live',
        title: this.$route.query.name
      }
    }

    // 如果已经装修过直播页或者装修页
    if (
      this.$route.query.isLiveDecorated ||
      this.$route.query.isPreviewDecorated
    ) {
      await this.getPageInfo()
    }
    // 如果templateId存在，则表示从使用模板跳转装修页面，需要对resultList进行重置，不使用默认数据
    if (this.$route.query.templateId) {
      await this.getTemplateDetail()
    }
    // 优先获取文档信息，主动获取中控台是否文档有绑定并和拖拉的文档组件的数据进行绑定，不管中控台文档按钮是否打开
    // this.$route.query.type === 'live' &&  await this.getWord();
    // 查看中控台文档按钮是否打开,如果打开则resultList中默认存在文档组件，否则不存在
    // this.$route.query.type === 'live' && await this.getLiveSwitch();
    // 初始化完毕，对resultList的限制个数进行二次处理
    if (this.resultList.length > 0) {
      await this.calcLimit(this.resultList)
    }
    this.resultListCopy = JSON.parse(JSON.stringify(this.resultList))
    this.initOver = true
    this.$forceUpdate()
  },
  methods: {
    // 如果已经装修过直播页或者装修页,则调用该接口初始化装修信息
    async getPageInfo() {
      const res = await this.$serve.activity.getLiveDesignerInfo({
        queryId: this.$route.params.id,
        params: {
          time: new Date().getTime()
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      if (this.$route.query.isLiveDecorated) {
        this.resultList = JSON.parse(res.livePageData)
        // console.log(this.resultList);
        let timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          this.isChange = false
        }, 1000)
      }
      if (this.$route.query.isPreviewDecorated) {
        this.resultList = JSON.parse(res.previewPageData)
        // console.log(this.resultList);
        let timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          this.isChange = false
        }, 1000)
      }
      // 特殊处理欢迎页
      let timer3 = setTimeout(() => {
        clearTimeout(timer3)
        timer3 = null
        if (JSON.stringify(this.resultList).indexOf('indexOfGuideSwitch') > 0) {
          let domsCommon = [...document.getElementsByClassName('com-common')]
          if (domsCommon.length > 0) {
            domsCommon.forEach((item) => {
              item.style.display = 'none'
            })
          }
          let domsGuide = [...document.getElementsByClassName('com-guide')]
          if (domsGuide.length > 0) {
            domsGuide[0].style.display = 'block'
          }
        } else {
          let domsCommon = [...document.getElementsByClassName('com-common')]
          if (domsCommon.length > 0) {
            domsCommon.forEach((item) => {
              item.style.display = 'block'
            })
          }
          let domsGuide = [...document.getElementsByClassName('com-guide')]
          if (domsGuide.length > 0) {
            domsGuide[0].style.display = 'none'
          }
        }
      })
    },
    // 如果存在templateId则表示从使用模版功能过来，调用该接口
    async getTemplateDetail() {
      const res = await this.$serve.activity.getTemplateDetail({
        queryId: this.$route.query.templateId
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.resultList = JSON.parse(res.data)
      // 特殊处理欢迎页
      let timer3 = setTimeout(() => {
        clearTimeout(timer3)
        timer3 = null
        if (JSON.stringify(this.resultList).indexOf('indexOfGuideSwitch') > 0) {
          let domsCommon = [...document.getElementsByClassName('com-common')]
          if (domsCommon.length > 0) {
            domsCommon.forEach((item) => {
              item.style.display = 'none'
            })
          }
          let domsGuide = [...document.getElementsByClassName('com-guide')]
          if (domsGuide.length > 0) {
            domsGuide[0].style.display = 'block'
          }
        } else {
          let domsCommon = [...document.getElementsByClassName('com-common')]
          if (domsCommon.length > 0) {
            domsCommon.forEach((item) => {
              item.style.display = 'block'
            })
          }
          let domsGuide = [...document.getElementsByClassName('com-guide')]
          if (domsGuide.length > 0) {
            domsGuide[0].style.display = 'none'
          }
        }
      })
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
        this.isChange = false
      }, 1000)
    },
    // 组件拖拽过程中对id进行重置
    checkMove(e) {
      if (e.draggedContext.element) {
        e.draggedContext.element.id = uid()
      } else {
        e.draggedContext.id = uid()
      }
    },
    // 点击组件新增
    addNode(item) {
      if (item.limit && item.existence >= item.limit) {
        this.$message({
          message: "You can only add this block once and you've reached the limit.",
          type: 'error',
          duration: 1000
        })
        return false
      }
      if (this.noAdd) {
        this.$message({
          message: 'please switch to Live Page！',
          type: 'error',
          duration: 1000
        })
        return false
      }
      this.currentActive = item.type
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
        this.currentActive = ''
      }, 50)
      let itemC = JSON.parse(JSON.stringify(item))
      itemC.id = uid()
      this.resultList.push(itemC)
      let timer2 = setTimeout(() => {
        clearTimeout(timer2)
        timer2 = null
        document.getElementById('resultDrag').scrollIntoView()
      }, 50)
      if (this.resultList.length > 0) {
        this.calcLimit(this.resultList)
      }
      // 使用dom方式特殊处理欢迎页
      if (this.noAdd === undefined) {
        let timer3 = setTimeout(() => {
          clearTimeout(timer3)
          timer3 = null
          if (
            JSON.stringify(this.resultList).indexOf('indexOfGuideSwitch') > 0
          ) {
            let domsCommon = [...document.getElementsByClassName('com-common')]
            if (domsCommon.length > 0) {
              domsCommon.forEach((item) => {
                item.style.display = 'none'
              })
            }
            let domsGuide = [...document.getElementsByClassName('com-guide')]
            if (domsGuide.length > 0) {
              domsGuide[0].style.display = 'block'
            }
          } else {
            let domsCommon = [...document.getElementsByClassName('com-common')]
            if (domsCommon.length > 0) {
              domsCommon.forEach((item) => {
                item.style.display = 'block'
              })
            }
            let domsGuide = [...document.getElementsByClassName('com-guide')]
            if (domsGuide.length > 0) {
              domsGuide[0].style.display = 'none'
            }
          }
        }, 70)
      }
    },
    // 组件拖拽成功后需要对左侧可拖拽组件重置
    dragAdd() {
      if (this.resultList.length > 0) {
        this.calcLimit(this.resultList)
      }
      this.sourceList = JSON.parse(JSON.stringify(this.copySourceList))
      this.$forceUpdate()
      // 使用dom方式特殊处理欢迎页
      if (this.noAdd === undefined) {
        let timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          if (
            JSON.stringify(this.resultList).indexOf('indexOfGuideSwitch') > 0
          ) {
            let domsCommon = [...document.getElementsByClassName('com-common')]
            if (domsCommon.length > 0) {
              domsCommon.forEach((item) => {
                item.style.display = 'none'
              })
            }
            let domsGuide = [...document.getElementsByClassName('com-guide')]
            if (domsGuide.length > 0) {
              domsGuide[0].style.display = 'block'
            }
          } else {
            let domsCommon = [...document.getElementsByClassName('com-common')]
            if (domsCommon.length > 0) {
              domsCommon.forEach((item) => {
                item.style.display = 'block'
              })
            }
            let domsGuide = [...document.getElementsByClassName('com-guide')]
            if (domsGuide.length > 0) {
              domsGuide[0].style.display = 'none'
            }
          }
        }, 70)
      }
    },
    // 结果数据传输到父级
    updataResult(data, data2, data3) {
      // Object.assign(this.resultList, data);
      this.$emit('updataResult', data2, data3)
    },
    // 关闭右侧抽屉
    closeEdit() {
      if (this.resultList.length > 0) {
        this.calcLimit(this.resultList)
      }
      this.$emit('closeEdit')
    },
    changeTheme(item) {
      this.stylesheet = {
        type: item.name,
        theme: {
          name: item.name,
          backgroundColor: item.colors[0], // 背景色
          buttonColor: item.colors[1], // 主色调
          primaryColor: item.colors[2], // 主要文字色
          secondaryColor: item.colors[3] // 次要文字色
        },
        style: {
          backgroundColor: {
            label: '背景色',
            value: item.colors[0]
          },
          buttonColor: {
            label: '主色调',
            value: item.colors[1]
          },
          primaryColor: {
            label: '主要文字色',
            value: item.colors[2]
          },
          secondaryColor: {
            label: '次要文字色',
            value: item.colors[3]
          }
        }
      }
      // console.log(this.stylesheet.type);
      this.$emit('updataTheme', this.stylesheet)
    },
    // 对限制个数进行初始化处理
    calcLimit(modules) {
      // 限制个数重置0；
      this.sourceList.forEach((item) => {
        item.limit && (item.existence = 0)
      })
      this.copySourceList.forEach((item) => {
        item.limit && (item.existence = 0)
      })
      this.calc(modules)
    },
    calc(modules) {
      let that = this
      if (modules.length > 0) {
        modules.forEach((item) => {
          // 如果初始化的结果中存在有限制个数的组件，那么需要对备份列表和源列表中相应的组件进行限制
          if (item.limit) {
            for (let i = 0; i < that.sourceList.length; i++) {
              if (that.sourceList[i].type === item.type) {
                that.$set(
                  this.sourceList[i],
                  'existence',
                  ++that.sourceList[i].existence
                )
                that.$set(
                  this.copySourceList[i],
                  'existence',
                  ++that.copySourceList[i].existence
                )
                break
              }
            }
          }
          if (item.modules && item.modules.length > 0) {
            for (let i = 0; i < item.modules.length; i++) {
              if (item.modules[i].modules.length > 0) {
                this.calc(item.modules[i].modules)
              }
            }
          }
        })
      }
    },
    // 监控结果数组是否有变化，并给出刷新、关闭、返回时的提示
    equals(a, b) {
      if (a === b) return true
      if (a instanceof Date && b instanceof Date)
        return a.getTime() === b.getTime()
      if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
        return a === b
      if (a.prototype !== b.prototype) return false
      let keys = Object.keys(a)
      if (keys.length !== Object.keys(b).length) return false
      return keys.every((k) => this.equals(a[k], b[k]))
    },
    // 获取文档是否上传过信息，文档图片初始化
    // async getWord() {
    //   let res = await getDoc({
    //     liveId: this.$route.query.liveId,
    //   });
    //   if (res.fail()) {
    //     this.$message({
    //       message: res.message,
    //       type: "error",
    //       duration: 1000,
    //     });
    //     return;
    //   }
    //   this.images = res.data.images || [];
    //   this.copySourceList[12].images = res.data.images || [];
    //   this.sourceList[12].images = res.data.images || [];
    // },
    // async getOrg() {
    //   let res = await getOrg();
    //   if (res.code !== 200) {
    //     this.$message({
    //       message: res.msg,
    //       type: "error",
    //       duration: 1000,
    //     });
    //     return;
    //   }
    //   this.copySourceList[6].logoUrl = res.data.logo || "";
    //   this.sourceList[6].logoUrl = res.data.logo || "";
    //   this.copySourceList[6].username = res.data.name || "";
    //   this.sourceList[6].username = res.data.name || "";
    // },
    // // 获取中控台文档开关状态
    // async getLiveSwitch() {
    //   let res = await getLiveSwitch({liveId: this.$route.query.liveId, type: ['word']});
    //   if (this.$route.query.diy) {
    //     // 如果是从自定义进来
    //     if (res.data.word.status === 1) {
    //       // 中控台文档开关开着
    //       this.resultList.push({
    //         type: "file",
    //         active: false,
    //         isContainer: false,
    //         over: false,
    //         editing: false,
    //         stylesheet: {
    //           height: "auto",
    //           typeStyle: null,
    //           style: {},
    //         },
    //         images: this.images, // 文档图片
    //         islive: true, // 不是直播装修组件
    //         limit: 1, // 限制个数
    //         existence: 0, // 存在个数
    //       });
    //     }
    //   } else {
    //     // 如果是模板或已经装修进来，那么需要对模板的文档进行处理。
    //     this.calcTemplateFile(this.resultList);
    //   }
    //   let timer = setTimeout(() => {
    //     clearTimeout(timer);
    //     timer = null;
    //     this.isChange = false;
    //   }, 1000);
    // },
    calcTemplateFile(resultList) {
      for (let i = 0; i < resultList.length; i++) {
        if (resultList[i].type === 'file') {
          this.$set(resultList[i], 'images', this.images)
          break
        }
        if (resultList[i].modules && resultList[i].modules.length > 0) {
          let modules = resultList[i].modules
          for (let r = 0; r < modules.length; r++) {
            this.calcTemplateFile(modules[r].modules)
          }
        }
      }
    },
    renderNoAdd(data) {
      this.noAdd = data
    },
    deleteCom() {
      this.calcLimit(this.resultList)
    },
  },
  beforeDestroy() {
    window.onbeforeunload = null
  }
}
</script>

<style lang="scss" scoped>
@import './dragCom.scss';
</style>
