import { uid } from "uid";

// 拖拽源文件备份需复制
const copySourceList = [
  {
    type: "guide",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    indexOfGuideSwitch: '', // 用来检测装修中是否存在欢迎页
    guideSwitch: true, // 引导封面开关
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  // {
  //   type: "banner",
  //   active: false,
  //   isContainer: false,
  //   over: false,
  //   editing: false,
  //   stylesheet: {
  //     height: "auto",
  //     typeStyle: null,
  //     style: {},
  //   },
  //   coverType: 'default',
  //   defaultUrl: "https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/sJGQJAr2MpiXYKxbsxidpZFAecPRjKHb.png",
  //   coverUrl: "", // 直播封面
  // },
  {
    type: "video-list",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      style: {
        background: "",
      },
      background: "default",
    },
    looping: false,
    typeStyle: "col",
    title: "", //  列表标题
    titleSwitch: false, // 列表标题开启
    setting: 'resume',
    videoList: [
      // 视频列表
    ],
  },
  {
    type: "introduction",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        background: "",
        backgroundImage: "",
      },
      background: {
        type: "default",
        width: 375,
        height: 102,
        url: "",
      },
    },
    title: "Event Name", // 直播名称
    detailContent: "", // 直播介绍
    showDetail: false, // 直播详情开关
    time: "", // 直播时间
    location: "", // 直播地点
    share: false,
  },
  {
    type: "subscribe",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        background: "",
        backgroundImage: "",
      },
      background: {
        type: "default", // default
        url: "",
      },
    },
    logoUrl: "", // 企业logo
    username: "name placeholder", // 企业名称
    url: '',
  },
  {
    type: "file",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    images: [], // 文档图片
    titleSwitch: false, // 标题开启
    fileTitle: '',
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  {
    type: "album",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        overflowY: "hidden",
        height: "auto",
      },
    },
    albumId: "", // 相册ID
    titleSwitch: false, // 相册标题开启
    albumTitle: "Add an Image Group title", // 相册标题
    tableStyle: "fall", // 瀑布流
    albumName: "", // 相册名称
    albumShotDate: "", // 相册日期
    albumList: [], // 相册相片列表
  },
  {
    type: "video-player",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    coverUrl: "",
    looping: false,
    countdown: false,
    countdownPosition: 'top',
    trailer: {
      enabled: false,
      url: "",
      coverUrl: "",
      title: "",
    },
    islive: true, // 是直播装修组件
  },
  {
    type: "live-info",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    text: "云犀直播正在进行时",
    title: "云犀直播",
    islive: false, // 不是直播装修组件
  },
  {
    type: "countdown",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    islive: false, // 不是直播装修组件
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  {
    type: "menu-tab",
    active: false,
    isContainer: true,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        height: "auto",
        overflowY: "auto",
        background: "",
        backgroundImage: "",
      },
      textAlign: "start",
      background: {
        type: "default",
        width: 0,
        height: 0,
        url: "",
      },
    },
    modules: [
      {
        name: "菜单1",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: true,
      },
      {
        name: "菜单2",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: false,
      },
      {
        name: "菜单3",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: false,
      },
      {
        name: "菜单4",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: false,
      },
    ],
    float: false,
    islive: true, // 是直播装修组件
  },
  {
    type: "comment",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "fixed",
      typeStyle: null,
      style: {
        height: "340px",
        background: "",
        backgroundImage: "",
      },
      background: {
        type: "default",
        url: "",
      },
    },
    lsId: "",
    activityId: 0,
    redpackValue: true, // 开启红包
    barrageValue: false, // 开启弹幕
    historyBarrageValue: false, // 开启历史弹幕
    redpackUrl: require("@/assets/images/designer/1.png"),
    islive: true, // 是直播装修组件
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  {
    type: "image-swiper",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    images: [], // 轮播图列表
    imageType: "1", // 图片大小类型
    islive: true, // 是直播装修组件
  },
  {
    type: "image-article",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        height: "344px",
      },
    },
    articleContent: "", // 富文本编辑器内容
    islive: true, // 是直播装修组件
  },
];
// 实际拖拽的源文件
const sourceList = [
  {
    type: "guide",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    indexOfGuideSwitch: '', // 用来检测装修中是否存在欢迎页
    mobileCover: '', // 欢迎页mobile图
    webCover: '', // 欢迎页web图
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  // {
  //   type: "banner",
  //   active: false,
  //   isContainer: false,
  //   over: false,
  //   editing: false,
  //   stylesheet: {
  //     height: "auto",
  //     typeStyle: null,
  //     style: {},
  //   },
  //   coverType: 'default',
  //   defaultUrl: "https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/sJGQJAr2MpiXYKxbsxidpZFAecPRjKHb.png",
  //   coverUrl: "", // 直播封面
  // },
  {
    type: "video-list",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      style: {
        background: "",
      },
      background: "default",
    },
    looping: false,
    typeStyle: "col",
    title: "", //  列表标题
    titleSwitch: false, // 列表标题开启
    setting: 'resume',
    videoList: [
      // 视频列表
    ],
  },
  {
    type: "introduction",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        background: "",
        backgroundImage: "",
      },
      background: {
        type: "default",
        width: 375,
        height: 102,
        url: "",
      },
    },
    title: "Event Name", // 直播名称
    detailContent: "", // 直播介绍
    showDetail: false, // 直播详情开关
    time: "", // 直播时间
    location: "", // 直播地点
    share: false,
  },
  {
    type: "subscribe",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        background: "",
        backgroundImage: "",
      },
      background: {
        type: "default", // default
        url: "",
      },
    },
    logoUrl: "", // 企业logo
    username: "name placeholder", // 企业名称
    url: '',
  },
  {
    type: "file",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    images: [], // 文档图片
    titleSwitch: false, // 标题开启
    fileTitle: '',
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  {
    type: "album",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        overflowY: "hidden",
        height: "auto",
      },
    },
    albumId: "", // 相册ID
    titleSwitch: false, // 相册标题开启
    albumTitle: "Add an Image Group title", // 相册标题
    tableStyle: "fall", // 瀑布流
    albumName: "", // 相册名称
    albumShotDate: "", // 相册日期
    albumList: [], // 相册相片列表
  },
  {
    type: "video-player",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    coverUrl: "",
    looping: false,
    countdown: false,
    countdownPosition: 'top',
    trailer: {
      enabled: false,
      url: "",
      coverUrl: "",
      title: "",
    },
    islive: true, // 是直播装修组件
  },
  {
    type: "live-info",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    text: "云犀直播正在进行时",
    title: "云犀直播",
    islive: false, // 不是直播装修组件
  },
  {
    type: "countdown",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    islive: false, // 不是直播装修组件
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  {
    type: "menu-tab",
    active: false,
    isContainer: true,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        height: "auto",
        overflowY: "auto",
        background: "",
        backgroundImage: "",
      },
      textAlign: "start",
      background: {
        type: "default",
        width: 0,
        height: 0,
        url: "",
      },
    },
    modules: [
      {
        name: "菜单1",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: true,
      },
      {
        name: "菜单2",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: false,
      },
      {
        name: "菜单3",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: false,
      },
      {
        name: "菜单4",
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
        show: false,
      },
    ],
    float: false,
    islive: true, // 是直播装修组件
  },
  {
    type: "comment",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "fixed",
      typeStyle: null,
      style: {
        height: "340px",
        background: "",
        backgroundImage: "",
      },
      background: {
        type: "default",
        url: "",
      },
    },
    lsId: "",
    activityId: 0,
    redpackValue: true, // 开启红包
    barrageValue: false, // 开启弹幕
    historyBarrageValue: false, // 开启历史弹幕
    redpackUrl: require("@/assets/images/designer/1.png"),
    islive: true, // 是直播装修组件
    limit: 1, // 限制个数
    existence: 0, // 存在个数
  },
  {
    type: "image-swiper",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    images: [], // 轮播图列表
    imageType: "1", // 图片大小类型
    islive: true, // 是直播装修组件
  },
  {
    type: "image-article",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {
        height: "344px",
      },
    },
    articleContent: "", // 富文本编辑器内容
    islive: true, // 是直播装修组件
  },
];
// 直播初始化装修
const resultList = [
  {
    id: uid(),
    type: "banner",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    coverType: 'default',
    defaultUrl: "https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/sJGQJAr2MpiXYKxbsxidpZFAecPRjKHb.png",
    coverUrl: "", // 直播封面
  },
  {
    id: uid(),
    type: "video-player",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    coverUrl: "",
    looping: false,
    countdown: false,
    countdownPosition: 'top',
    trailer: {
      enabled: false,
      url: "",
      coverUrl: "",
      title: "",
    },
  },
];
// 预告初始化装修
const ygResultList = [
  {
    id: uid(),
    type: "banner",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    coverUrl: "",
    islive: false, // 不是直播装修组件
  },
  {
    id: uid(),
    type: "live-info",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    text: "云犀直播正在进行时",
    title: "云犀直播",
    islive: false, // 不是直播装修组件
  },
  {
    id: uid(),
    type: "countdown",
    active: false,
    isContainer: false,
    over: false,
    editing: false,
    stylesheet: {
      height: "auto",
      typeStyle: null,
      style: {},
    },
    islive: false, // 不是直播装修组件
    limit: 1, // 限制个数
    existence: 1, // 存在个数
  },
];
// 风格列表
const colorSchemes = [
  {
    label: "科技",
    name: "white-blue",
    colors: ["#ffffff", "#1374f6", "#333333", "#999999"],
  },
  {
    label: "电商",
    name: "white-orange",
    colors: ["#ffffff", "#FF6600", "#333333", "#999999"],
  },
  {
    label: "教育",
    name: "white-green",
    colors: ["#ffffff", "#52B788", "#333333", "#999999"],
  },
  {
    label: "经典",
    name: "white-red",
    colors: ["#ffffff", "#FF4444", "#333333", "#999999"],
  },
  {
    label: "政务",
    name: "white-purple",
    colors: ["#ffffff", "#091D62", "#333333", "#999999"],
  },
  {
    label: "展会",
    name: "black-red",
    colors: ["#0A122A", "#E3171A", "#FFFFFF", "#848894"],
  },
  {
    label: "节日",
    name: "red-yellow",
    colors: ["#D61B1B", "#E8C753", "#FFFFFF", "#EA8D8D"],
  },
  {
    label: "黑金",
    name: "black-yellow",
    colors: ["#0A122A", "#CEAA5A", "#FFFFFF", "#848894"],
  },
  {
    label: "黑蓝",
    name: "black-blue",
    colors: ["#0A122A", "#0088FF", "#FFFFFF", "#848894"],
  },
];

export default {
  copySourceList,
  sourceList,
  resultList,
  colorSchemes,
  ygResultList,
};
