<template>
  <div class="page-designer">
    <header class="header">
      <div class="back" @click="goback">
        <i class="el-icon-arrow-left"></i>
        <span class="live-indicator" v-if="false">{{
          this.$route.query.type === "live" ? "直播页" : "预告页"
        }}</span>
        <span class="back-title">Back to Event Setup</span>
      </div>
      <div class="header-extra">

        <y-button size="small" plain @click="saveTemplate" :loading="loadingSave">
          {{ $route.query.templateId && $route.query.edit ? 'Save and Exit' : 'Save as a Template' }}
        </y-button>
        <y-button v-if="!$route.query.edit" size="small" type="primary" @click="releaseTemplate" :loading="loadingApply">
          Apply
        </y-button>
      </div>
    </header>
    <div class="section">
      <div class="left-tab" v-if="false">
        <div @click="handleOpenSetting(1)" class="setting-tab">
          <div class="setting-tab-box">
            <img
              v-show="currentTab === 'component'"
              src="~@assets/images/designer/component-status1.svg"
            />
            <img
              v-show="currentTab !== 'component'"
              src="~@assets/images/designer/component-status0.svg"
            />
            <span
              :class="
                currentTab === 'component'
                  ? 'setting-active-color'
                  : 'setting-color'
              "
              >组件</span
            >
          </div>
          <div class="line-box" v-show="currentTab === 'component'"></div>
        </div>

        <div @click="handleOpenSetting(3)" class="setting-tab">
          <div class="setting-tab-box">
            <img
              v-show="currentTab === 'theme'"
              src="~@assets/images/designer/theme-status1.svg"
            />
            <img
              v-show="currentTab !== 'theme'"
              src="~@assets/images/designer/theme-status0.svg"
            />
            <span
              :class="
                currentTab === 'theme'
                  ? 'setting-active-color'
                  : 'setting-color'
              "
              >风格</span
            >
          </div>
          <div class="line-box" v-show="currentTab === 'theme'"></div>
        </div>
      </div>
      <div class="center-box">
        <drag-com
          ref="dragCom"
          @updataResult="updataResult"
          @closeEdit="closeEdit"
          :currentTab="currentTab"
          :stylesheet="stylesheet"
          @updataTheme="updataTheme"
        ></drag-com>
      </div>
    </div>
    <el-drawer
      title="我是拖拉拽组件"
      v-if="showDrawer"
      :visible.sync="drawer"
      :with-header="false"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :size="376"
      :modal="false"
    >
      <div style="overflow-x:hidden;">
        <div class="draw-header">
          <div class="title">
            {{ currentEdit.name }}
            <div v-if="currentEdit.name === 'Live Streaming'" class="video-tip">This block is for live streaming. You can change the position of it but not to delete it. </div>
          </div>
          <div class="close" @click.stop="closeDrawer">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <component :is="currentEdit.edit" :data="currentCom"></component>
      </div>
    </el-drawer>
    <el-drawer
      title="我是风格主题"
      v-if="showDrawerTheme"
      :visible.sync="drawerTheme"
      :with-header="false"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :size="376"
      :modal="false"
    >
      <div>
        <div class="draw-header">
          <div class="title">配色设置</div>
          <div class="close" style="color: #1374f6" @click.stop="resetTheme">
            重置
          </div>
        </div>
        <div class="color-box">
          <div class="color-item">
            <div class="key">{{ stylesheet.style.backgroundColor.label }}</div>
            <div class="value">
              <el-color-picker
                v-model="stylesheet.style.backgroundColor.value"
                show-alpha
              ></el-color-picker>
            </div>
          </div>
          <div class="color-item">
            <div class="key">{{ stylesheet.style.buttonColor.label }}</div>
            <div class="value">
              <el-color-picker
                v-model="stylesheet.style.buttonColor.value"
                show-alpha
              ></el-color-picker>
            </div>
          </div>
          <div class="color-item">
            <div class="key">{{ stylesheet.style.primaryColor.label }}</div>
            <div class="value">
              <el-color-picker
                v-model="stylesheet.style.primaryColor.value"
                show-alpha
              ></el-color-picker>
            </div>
          </div>
          <div class="color-item">
            <div class="key">{{ stylesheet.style.secondaryColor.label }}</div>
            <div class="value">
              <el-color-picker
                v-model="stylesheet.style.secondaryColor.value"
                show-alpha
              ></el-color-picker>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { uid } from "uid";
import dragCom from "./components/dragCom";

export default {
  components: {
    dragCom,
  },
  data() {
    return {
      // 风格
      stylesheet: {
        type: "white-blue",
        theme: {
          name: "white-blue",
          backgroundColor: "#FFFFFF", // 背景色
          buttonColor: "#1374F6", // 主色调
          primaryColor: "#333333", // 主要文字色
          secondaryColor: "#999999", // 次要文字色
        },
        style: {
          backgroundColor: {
            label: "背景色",
            value: "#FFFFFF",
          },
          buttonColor: {
            label: "主色调",
            value: "#1374F6",
          },
          primaryColor: {
            label: "主要文字色",
            value: "#999999",
          },
          secondaryColor: {
            label: "次要文字色",
            value: "#999999",
          },
        },
      },
      currentTab: "component",
      drawer: false,
      showDrawer: false, // vIf产生，以方便数据的变动
      currentEdit: {
        name: "",
        edit: "",
      },
      currentCom: {},
      showDrawerTheme: false,
      drawerTheme: false,
      templateName: "", // 模板名称
      loadingSave: false,
      loadingApply: false,
    };
  },
  async created() {
    this.$route.query.liveId = this.$route.params.id;
  },
  methods: {
    handleOpenSetting(type) {
      let tiemr = null;
      switch (type) {
        case 1:
          if (this.currentTab === "component") {
            return false;
          }
          this.closeDrawerTheme();
          this.currentTab = "component";
          this.changeActive(this.$refs.dragCom.resultList);
          this.closeEdit();
          break;
        case 3:
          if (this.currentTab === "theme") {
            return false;
          }
          this.currentTab = "theme";
          this.changeActive(this.$refs.dragCom.resultList);
          this.closeEdit();
          this.showDrawerTheme = true;
          tiemr = setTimeout(() => {
            clearTimeout(tiemr);
            tiemr = null;
            this.drawerTheme = true;
          }, 200);
          break;
        default:
          break;
      }
    },
    updataResult(data2, data3) {
      // 将所有的激活状态取消
      this.changeActive(this.$refs.dragCom.resultList);
      this.currentEdit = data2;
      this.currentCom = data3;
      if (data3.type !== "countdown" && data3.type !== "banner") {
        this.showDrawer = true;
        let tiemr = setTimeout(() => {
          clearTimeout(tiemr);
          tiemr = null;
          this.drawer = true;
          document.querySelector('.page-result').classList.add('marginRight')
        }, 200);
      } else {
        this.closeEdit();
      }
    },
    closeDrawer() {
      this.changeActive(this.$refs.dragCom.resultList);
      this.drawer = false;
      this.currentCom = {};
      this.currentEdit = {};
      document.querySelector('.page-result').classList.remove('marginRight')
      let tiemr = setTimeout(() => {
        clearTimeout(tiemr);
        tiemr = null;
        this.showDrawer = false;
      }, 200);
    },
    closeEdit() {
      this.closeDrawer();
    },
    changeActive(modules) {
      modules.forEach((item) => {
        if (item.active) {
          item.active = false;
        }
        if (item.modules) {
          this.changeActive(item.modules);
        }
      });
    },
    async releaseTemplate() {
      await this.changeActive(this.$refs.dragCom.resultList);
      await this.closeEdit();
      const val = true; // await this.checkall(this.$refs.dragCom.resultList);
      if (val) {
        const data = {
          theme: this.stylesheet,
          modules: this.$refs.dragCom.resultList,
        };
        this.loadingApply = true;
        const res = await this.$serve.activity.applyDesigner({
          data: {
            type: 1,
            data: JSON.stringify(data.modules),
            activityId: this.$route.params.id
          }
        })
        this.loadingApply = false;
        if (res === this.$serve.FAIL) {
          return false
        }
        this.$message({
          message: "Apply Success！",
          type: "success",
          duration: 1000,
        });
        const copyResult = this.$refs.dragCom.resultListCopy;
        const result = this.$refs.dragCom.resultList;
        // 对比两份数据，如果文档改变，怎通知观看端，目前只需处理文档内容改变（1 -> 2, 0 -> 1/ 1 -> 0 不做通知）
        if (
          copyResult.length > 0 && result.length > 0 &&
          JSON.stringify(copyResult).indexOf('fileTitle') > -1 &&
          JSON.stringify(result).indexOf('fileTitle') > -1
        ) {
          copyResult.forEach(item => {
            if (item.type === 'file') {
              result.forEach(item2 => {
                if (item2.type === 'file') {
                  if (item.images.length !== item2.images.length || JSON.stringify(item.images) !== JSON.stringify(item2.images)) {
                    this.$serve.transfer.noticeC({
                      queryId: this.$route.params.id
                    })
                  }
                }
              })
            }
          })
        }
        this.$router.go(-1)
      }
    },
    async saveTemplate() {
      await this.changeActive(this.$refs.dragCom.resultList);
      await this.closeEdit();
      const data = {
        theme: this.stylesheet,
        modules: JSON.parse(JSON.stringify(this.$refs.dragCom.resultList)),
      };
      // await this.clearall(data.modules);
      if (!this.$route.query.templateId) {
        await this.popForName(data);
      } else {
        await this.updateData(data);
      }
    },
    async saveData(data) {
      this.loadingSave = true;
      const res = await this.$serve.activity.createTemplate({
        data: {
          name: this.templateName,
          type: 1,
          data: JSON.stringify(data.modules)
        }
      })
      this.loadingSave = false;
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message({
        message: `Save Success！`,
        type: "success",
        duration: 1000,
      });
      if (this.$route.query.edit) {
        this.$router.go(-1)
      }
    },
    async updateData(data) {
      this.loadingSave = true;
      const res = await this.$serve.activity.updataTemplate({
        data: {
          templateId: this.$route.query.templateId,
          data: JSON.stringify(data.modules)
        }
      })
      this.loadingSave = false;
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message({
        message: `Save Success！`,
        type: "success",
        duration: 1000,
      })
      if (this.$route.query.edit) {
        this.$router.go(-1)
      }
    },
    closeDrawerTheme() {
      this.drawerTheme = false;
      let tiemr = setTimeout(() => {
        clearTimeout(tiemr);
        tiemr = null;
        this.showDrawerTheme = false;
      }, 200);
    },
    resetTheme() {
      this.stylesheet.style = {
        backgroundColor: {
          label: "背景色",
          value: "#FFFFFF",
        },
        buttonColor: {
          label: "主色调",
          value: "#1374F6",
        },
        primaryColor: {
          label: "主要文字色",
          value: "#999999",
        },
        secondaryColor: {
          label: "次要文字色",
          value: "#999999",
        },
      };
    },
    updataTheme(theme) {
      this.stylesheet = theme;
    },
    checkall(resultList) {
      for (let i = 0; i < resultList.length; i++) {
        switch (resultList[i].type) {
          case "banner":
            if (!this.bannerCheck(resultList[i])) {
              return false;
            }
            break;
          case "live-info":
            if (!this.liveInfoCheck(resultList[i])) {
              return false;
            }
            break;
          case "countdown":
            break;
          case "menu-tab":
            if (!this.menuTabCheck(resultList[i])) {
              return false;
            }
            if (resultList[i].modules && resultList[i].modules.length > 0) {
              const modules = resultList[i].modules;
              for (let i = 0; i < modules.length; i++) {
                if (!this.checkall(modules[i].modules)) {
                  return false;
                }
              }
            }
            break;
          case "album":
            if (!this.albumCheck(resultList[i])) {
              return false;
            }
            break;
          case "video-player":
            if (!this.videoPlayerCheck(resultList[i])) {
              return false;
            }
            break;
          case "subscribe":
            if (!this.subscribeCheck(resultList[i])) {
              return false;
            }
            break;
          case "introduction":
            if (!this.introductionCheck(resultList[i])) {
              return false;
            }
            break;
          case "comment":
            if (!this.commentCheck(resultList[i])) {
              return false;
            }
            break;
          case "video-list":
            if (!this.videoListCheck(resultList[i])) {
              return false;
            }
            break;
          case "image-swiper":
            if (!this.imageSwiperCheck(resultList[i])) {
              return false;
            }
            break;
          case "image-article":
            if (!this.imageArticleCheck(resultList[i])) {
              return false;
            }
            break;
          case "file":
            if (!this.fileCheck(resultList[i])) {
              return false;
            }
            break;
        }
      }
      return true;
    },
    // 菜单栏发布校验
    menuTabCheck(item) {
      // 菜单名称不能为空
      for (let i = 0; i < item.modules.length; i++) {
        if (!item.modules[i].name) {
          item.active = true;
          this.$message({
            message: "请输入菜单名称！",
            type: "error",
            duration: 1000,
          });
          let timer2 = setTimeout(() => {
            clearTimeout(timer2);
            timer2 = null;
            document.getElementById(item.id).scrollIntoView();
          }, 50);
          return false;
        }
      }
      // 菜单栏中的菜单必须存在组件
      for (let i = 0; i < item.modules.length; i++) {
        if (item.modules[i].modules.length === 0) {
          item.active = true;
          this.$message({
            message: `${item.modules[i].name}内容不能为空！`,
            type: "error",
            duration: 1000,
          });
          let timer2 = setTimeout(() => {
            clearTimeout(timer2);
            timer2 = null;
            document.getElementById(item.id).scrollIntoView();
          }, 50);
          return false;
        }
      }
      // 如果选择了背景图为封面则必须上传图片
      // if (
      //   item.stylesheet.background.type !== "default" &&
      //   !item.stylesheet.background.url
      // ) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传背景图片！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      return true;
    },
    // 相册校验
    albumCheck(item) {
      // 如果相册标题开启
      // if (item.titleSwitch && !item.albumTitle) {
      //   item.active = true;
      //   this.$message({
      //     message: "请输入相册标题！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      if (!item.albumId) {
        item.active = true;
        this.$message({
          message: "请选择相册！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      return true;
    },
    // 播放器校验
    videoPlayerCheck(item) {
      // if (!item.coverUrl) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传封面！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      if (item.source.type === "live" && !item.source.id) {
        item.active = true;
        this.$message({
          message: "请选择视频源！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      if (item.source.type === "media" && !item.source.url) {
        item.active = true;
        this.$message({
          message: "请选择视频源！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      if (
        item.source.type === "live" &&
        item.trailer.enabled &&
        !item.trailer.url
      ) {
        item.active = true;
        this.$message({
          message: "请选择预告片！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      return true;
    },
    // 企业名片校验
    subscribeCheck(item) {
      // if (
      //   item.stylesheet.background.type !== "default" &&
      //   !item.stylesheet.background.url
      // ) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传背景图片！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      // if (!item.logoUrl) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传企业LOGO！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      if (!item.username) {
        item.active = true;
        this.$message({
          message: "请输入企业名称！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      if (!item.qrcodeUrl) {
        item.active = true;
        this.$message({
          message: "请上传企业公众号！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      return true;
    },
    // 直播简介校验
    introductionCheck() {
      // if (
      //   item.stylesheet.background.type !== "default" &&
      //   !item.stylesheet.background.url
      // ) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传背景图片！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      return true;
    },
    // 评论校验
    commentCheck() {
      // if (
      //   item.stylesheet.background.type !== "default" &&
      //   !item.stylesheet.background.url
      // ) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传背景图片！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      return true;
    },
    // 视频列表校验
    videoListCheck(item) {
      // if (item.titleSwitch && !item.title) {
      //   item.active = true;
      //   this.$message({
      //     message: "请输入列表标题！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      if (item.videoList.length === 0) {
        item.active = true;
        this.$message({
          message: "请添加视频！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      return true;
    },
    // 轮播图校验
    imageSwiperCheck(item) {
      if (item.images.length === 0) {
        item.active = true;
        this.$message({
          message: "请添加图片！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      for (let i = 0; i < item.images.length; i++) {
        if (!item.images[i].imageUrl) {
          item.active = true;
          this.$message({
            message: "请添加图片！",
            type: "error",
            duration: 1000,
          });
          let timer2 = setTimeout(() => {
            clearTimeout(timer2);
            timer2 = null;
            document.getElementById(item.id).scrollIntoView();
          }, 50);
          return false;
        }
      }
      return true;
    },
    //  图文校验
    imageArticleCheck() {
      // if (!item.articleContent) {
      //   item.active = true;
      //   this.$message({
      //     message: "请编辑图文！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      return true;
    },
    // 文档校验
    fileCheck(item) {
      if (!item.images || item.images.length === 0) {
        item.active = true;
        this.$message({
          message: "请上传文档！",
          type: "error",
          duration: 1000,
        });
        let timer2 = setTimeout(() => {
          clearTimeout(timer2);
          timer2 = null;
          document.getElementById(item.id).scrollIntoView();
        }, 50);
        return false;
      }
      return true;
    },
    // 预告页封面校验
    bannerCheck() {
      // if (!item.coverUrl) {
      //   item.active = true;
      //   this.$message({
      //     message: "请上传封面！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      return true;
    },
    // 直播信息校验
    liveInfoCheck() {
      // if (!item.title) {
      //   item.active = true;
      //   this.$message({
      //     message: "请输入直播标题！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      // if (!item.text) {
      //   item.active = true;
      //   this.$message({
      //     message: "请输入直播介绍！",
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      return true;
    },
    // 新模板保存时弹窗设置模板名称
    popForName(data) {
      const h = this.$createElement;
      const inputKey = uid();
      this.$msgbox({
        title: "Name Your Template",
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        customClass: "template-title",
        message: h("div", null, [
          h(
            "div",
            {
              staticStyle: {
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              },
            },
            [
              h(
                "div",
                {
                  staticClass: "el-input",
                  staticStyle: { flex: "1" },
                },
                [
                  h("input", {
                    key: inputKey,
                    attrs: {
                      autocomplete: "off",
                      placeholder: "Please enter a template name",
                      maxlength: 32,
                      value: this.templateName,
                    },
                    on: {
                      change: (e) => void (this.templateName = e.target.value),
                    },
                    staticClass: "el-input__inner",
                  }),
                ]
              ),
            ]
          ),
        ]),
      })
        .then(() => {
          if (!this.templateName.trim()) {
            this.$confirm("The  Template name cannot be empty, please enter a valid name.", "Prompt", {
              confirmButtonText: "Done",
              cancelButtonText: "Cancel",
              type: "warning",
            })
              .then(() => {
                this.templateName = "";
                this.popForName();
              })
              .catch(window.noop);
            return;
          }
          this.saveData(data);
        })
        .catch(window.noop);
    },
    goback() {
      if (this.$refs.dragCom.isChange) {
        let msg = this.$route.query.edit ? 'Changes not saved. Are you sure you want to leave?' : 'The change has not been applied, are you sure you want to leave？'
        this.$confirm(msg, "Prompt", {
          confirmButtonText: "Done",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.$router.go(-1)
        });
      } else {
        this.$router.go(-1)
      }
    },
    clearall(resultList) {
      for (let i = 0; i < resultList.length; i++) {
        switch (resultList[i].type) {
          case "banner":
            break;
          case "live-info":
            break;
          case "countdown":
            break;
          case "menu-tab":
            if (resultList[i].modules && resultList[i].modules.length > 0) {
              const modules = resultList[i].modules;
              for (let i = 0; i < modules.length; i++) {
                this.clearall(modules[i].modules);
              }
            }
            break;
          case "album":
            break;
          case "video-player":
            resultList[i].source.id = "";
            resultList[i].source.title = "";
            resultList[i].source.url = "";
            resultList[i].trailer.url = "";
            break;
          case "subscribe":
            break;
          case "introduction":
            break;
          case "comment":
            break;
          case "video-list":
            resultList[i].videoList = [];
            break;
          case "image-swiper":
            break;
          case "image-article":
            break;
          case "file":
            resultList[i].images = [];
            break;
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import "./designer.scss";
</style>
